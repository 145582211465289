// src/components/Settings.tsx
import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import axiosInstance from '../api/axios'; // axios 인스턴스 임포트
import { useAuth } from '../contexts/AuthContext'; // 인증 컨텍스트 임포트
import { debounce } from 'lodash'; // lodash debounce for better handling



interface UserInfo {
  uid: string;
  email: string;
  display_name: string;
  photo_url: string;
  email_verified: boolean;
  nickname: string;
  user_level: number;
  subscription_active: boolean;
  subscription_expires_at: string | null;
  credit: number;
  user_status: number;
  current_plan: number;
  plan_status: number;
  theme: number;
}

const userLevelMap: { [key: number]: string } = {
  0: 'Free',
  1: 'Premium',
  2: 'Custom',
};

const userStatusMap: { [key: number]: string } = {
  0: 'Dormant',
  1: 'Active',
};

const currentPlanMap: { [key: number]: string } = {
  0: 'Free',
  1: 'Pay-as-you-Go',
  2: 'Flat rate plan',
};

const planStatusMap: { [key: number]: string } = {
  0: 'Dormant',
  1: 'Active',
};

const themeOptions: { label: string; value: number }[] = [
  { label: 'Default', value: 0 },
  { label: 'Blue', value: 1 },
  { label: 'Violet', value: 2 },
];

const Settings: React.FC = () => {
  const { currentUser } = useAuth(); // 현재 사용자 정보 가져오기
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [updating, setUpdating] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  // 폼 필드 상태
  const [nickname, setNickname] = useState<string>('');
  const [initialNickname, setInitialNickname] = useState<string>(''); // New state to track initial nickname
  const [theme, setTheme] = useState<number>(0);

  // Nickname Availability States
  const [isNicknameAvailable, setIsNicknameAvailable] = useState<boolean | null>(null);
  const [isCheckingNickname, setIsCheckingNickname] = useState<boolean>(false);

  // 사용자 정보 가져오기
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axiosInstance.get('/me');
        setUserInfo(response.data);
        // 폼 필드 초기화
        setNickname(response.data.nickname || '');
        setInitialNickname(response.data.nickname || ''); // Set initial nickname
        setTheme(response.data.theme || 0);
      } catch (err: any) {
        console.error('사용자 정보 가져오기 실패:', err);
        setError('Failed to get user information.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  // Memoized debounced function to check nickname availability
  const checkNicknameAvailability = useCallback(
    debounce(async (currentNickname: string) => {
      if (currentNickname.trim() === '') {
        setIsNicknameAvailable(null);
        setIsCheckingNickname(false);
        return;
      }

      try {
        setIsCheckingNickname(true);
        const response = await axiosInstance.get('/check-nickname', {
          params: { nickname: currentNickname },
        });
        setIsNicknameAvailable(response.data.available);
      } catch (err: any) {
        console.error('닉네임 중복 체크 실패:', err);
        setIsNicknameAvailable(false);
      } finally {
        setIsCheckingNickname(false);
      }
    }, 500), // 500ms debounce
    [] // Empty dependency array ensures it's created only once
  );

  // Effect to watch nickname changes
  useEffect(() => {
    if (nickname !== initialNickname) {
      checkNicknameAvailability(nickname);
    } else {
      setIsNicknameAvailable(null); // Reset availability if nickname is unchanged
    }
    // Cleanup on unmount
    return () => {
      checkNicknameAvailability.cancel();
    };
  }, [nickname, initialNickname, checkNicknameAvailability]);

  // 폼 제출 핸들러
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Prevent submission if nickname is taken
    if (isNicknameAvailable === false) {
      setError('The nickname is already in use. Please choose a different nickname.');
      return;
    }

    setUpdating(true);
    setError('');
    setSuccess('');

    try {
      const formData = new FormData();
      if (nickname) formData.append('nickname', nickname);
      formData.append('theme', theme.toString());

      const response = await axiosInstance.put('/me', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUserInfo(response.data);
      setInitialNickname(response.data.nickname || ''); // Update initial nickname after successful update
      setSuccess('Your user information has been successfully updated.');

    // 페이지 새로고침
    window.location.reload(); // 업데이트 후 전체 페이지를 새로고침

    } catch (err: any) {
      console.error('사용자 정보 업데이트 실패:', err);
      setError(err.response?.data?.detail || 'Failed to update user information.');
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!userInfo) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6">Unable to retrieve user information.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Settings
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* 읽기 전용 섹션 */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Account Information
                </Typography>
                <Grid container spacing={2}>
                  {/* 이메일 */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      value={userInfo.email}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      sx={{ backgroundColor: '#f5f5f5' }}
                    />
                  </Grid>

                  {/* Nickname */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Nickname"
                      value={nickname}
                      onChange={(e) => setNickname(e.target.value)}
                      fullWidth
                      variant="outlined"
                      sx={{
                        backgroundColor: '#fffbea', // 수정 가능한 필드 배경색
                      }}
                      error={isNicknameAvailable === false}
                      helperText={
                        isCheckingNickname ? (
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            <CircularProgress size={16} sx={{ mr: 1 }} />
                            Checking for nickname duplicates...
                          </span>
                        ) : isNicknameAvailable === false
                          ? 'The nickname is already in use.'
                          : isNicknameAvailable === true
                            ? 'Available nicknames.'
                            : ''
                      }
                    />
                  </Grid>

                  {/* User Level */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="User Level"
                      value={userLevelMap[userInfo.user_level] || 'Unknown'}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      sx={{ backgroundColor: '#f5f5f5' }}
                    />
                  </Grid>

                  {/* Credit */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Credit"
                      value={userInfo.credit}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      sx={{ backgroundColor: '#f5f5f5' }}
                    />
                  </Grid>

                  {/* User Status */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="User Status"
                      value={userStatusMap[userInfo.user_status] || 'Unknown'}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      sx={{ backgroundColor: '#f5f5f5' }}
                    />
                  </Grid>

                  {/* Current Plan */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Current Plan"
                      value={currentPlanMap[userInfo.current_plan] || 'Unknown'}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      sx={{ backgroundColor: '#f5f5f5' }}
                    />
                  </Grid>

                  {/* Plan Status */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Plan Status"
                      value={planStatusMap[userInfo.plan_status] || 'Unknown'}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      sx={{ backgroundColor: '#f5f5f5' }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Divider sx={{ my: 3, width: '100%' }} />

              {/* 수정 가능한 섹션 */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Customize
                </Typography>
                <Grid container spacing={2}>
                  {/* Theme */}
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="theme-label">Theme</InputLabel>
                      <Select
                        labelId="theme-label"
                        label="Theme"
                        value={theme}
                        onChange={(e) => setTheme(Number(e.target.value))}
                        sx={{
                          backgroundColor: '#fffbea', // 수정 가능한 필드 배경색
                        }}
                      >
                        {themeOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* 제출 버튼 */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={updating || isNicknameAvailable === false || isCheckingNickname}
                  sx={{
                    height: '50px',
                    fontSize: '1.1rem',
                  }}
                >
                  {updating ? <CircularProgress size={24} /> : 'Update'}
                </Button>
              </Grid>
            </Grid>
          </form>

          {/* 에러 메시지 */}
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError('')}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>

          {/* 성공 메시지 */}
          <Snackbar
            open={!!success}
            autoHideDuration={6000}
            onClose={() => setSuccess('')}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Settings;
