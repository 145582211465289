// src/components/TabsContent/Flux1Schnell.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';

const Flux1Schnell: React.FC = () => {
  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        FLUX.1-schnell
      </Typography>
      <Typography variant="body1">
      This is the FLUX.1-schnell feature screen. Add something about your feature here.
      </Typography>
    </Box>
  );
};

export default Flux1Schnell;
