// src/components/TabsContent/SDXLLightning.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';

const SDXLLightning: React.FC = () => {
  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        SDXL Lightning
      </Typography>
      <Typography variant="body1">
      SDXL Lightning features screen. Add something about your feature here.
      </Typography>
    </Box>
  );
};

export default SDXLLightning;
