// src/pages/Success.tsx
import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Typography, Container } from "@mui/material";
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const Success: React.FC = () => {
    const location = useLocation();
    const { currentUser } = useAuth();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentId = query.get('payment_id');

        if (paymentId && currentUser) {
            // 결제 확인을 위해 백엔드에 요청
            const verifyPayment = async () => {
                try {
                    const token = await currentUser.getIdToken();
                    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/verify-payment`, { payment_id: paymentId }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (response.data.status === 'success') {
                        toast.success("Payment completed successfully!");
                    } else if (response.data.status === 'canceled') {
                        toast.error("Payment was cancelled.");
                    } else {
                        toast.warn(`Payment status: ${response.data.status}`);
                    }
                } catch (err: any) {
                    toast.error("Failed to verify payment.");
                }
            };
            verifyPayment();
        } else {
            // Free 구독 성공 시 메시지 표시
            const isFree = location.search.includes("payment_id=");
            if (!isFree) {
                toast.success("Free subscription has been activated!");
            }
        }
    }, [location, currentUser]);

    return (
        <Container style={{ textAlign: 'center', padding: '50px' }}>
            <Typography variant="h4" gutterBottom>
                Payment Successful!
            </Typography>
            <Typography variant="body1">
                Your subscription has been completed successfully.
            </Typography>
            <Button variant="contained" color="primary" component={Link} to="/profile" style={{ marginTop: '20px' }}>
                View My Profile
            </Button>
        </Container>
    );
};

export default Success;
