// src/pages/PaymentRedirect.tsx
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typography, Button, Container } from '@mui/material';

const PaymentRedirect: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentId = query.get('paymentId');
        const code = query.get('code');
        const message = query.get('message');

        if (code) {
            // Payment process error occurred
            toast.error(message || 'An error occurred during the payment process.');
            navigate('/subscribe');
        } else if (paymentId) {
            // 결제 성공 시, 서버에 결제 완료를 알립니다.
            fetch(`https://121.78.116.47/payment/complete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ paymentId }),
            })
            .then(async (response) => {
                if (response.ok) {
                    toast.success('Payment completed successfully!');
                    navigate('/profile');
                } else {
                    const errorData = await response.json();
                    toast.error(errorData.detail || 'An error occurred while processing payment completion.');
                    navigate('/subscribe');
                }
            })
            .catch(() => {
                toast.error('An error occurred while processing payment completion.');
                navigate('/subscribe');
            });
        } else {
            toast.error('Invalid payment information.');
            navigate('/subscribe');
        }
    }, [location, navigate]);

    return (
        <Container style={{ textAlign: 'center', padding: '50px' }}>
            <Typography variant="h4" gutterBottom>
                Processing Payment...
            </Typography>
            <Typography variant="body1">
                Please wait a moment.
            </Typography>
        </Container>
    );
};


export default PaymentRedirect;
