
// src/contexts/PromptContext.tsx
import React, { createContext, useState, ReactNode } from 'react';
import { Prompt } from '../types/Prompt';

interface PromptContextType {
  prompts: Prompt[];
  setPrompts: React.Dispatch<React.SetStateAction<Prompt[]>>;
  selectedPrompt: Prompt | null;
  setSelectedPrompt: React.Dispatch<React.SetStateAction<Prompt | null>>;
}

export const PromptContext = createContext<PromptContextType>({
  prompts: [],
  setPrompts: () => {},
  selectedPrompt: null,
  setSelectedPrompt: () => {},
});

interface PromptProviderProps {
  children: ReactNode;
}

export const PromptProvider: React.FC<PromptProviderProps> = ({ children }) => {
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);

  return (
    <PromptContext.Provider value={{ prompts, setPrompts, selectedPrompt, setSelectedPrompt }}>
      {children}
    </PromptContext.Provider>
  );
};
