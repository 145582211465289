// src/components/TabsContent/AssetsTab.tsx
import React, { useEffect, useState, useCallback } from 'react';
import {
  Grid,
  Card,
  CardMedia,
  Checkbox,
  IconButton,
  Box,
  Typography,
  CircularProgress,
  Button,
  Divider,
  TextField,
} from '@mui/material';
import { useFolderContext } from '../../contexts/FolderContext'; // Context Hook 임포트
import { SelectedImage, BgFile, Folder } from '../../types';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderIcon from '@mui/icons-material/Folder';
import SelectedOverlay from '../SelectedOverlay';
import axiosInstance from '../../api/axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // 상위 폴더로 돌아가기 아이콘

interface AssetsTabProps {
  userEmail: string; // 사용자 이메일
  setSelectedImage: (image: SelectedImage | null) => void;
  setDescribeText: (text: string) => void;
  setDontWantText: (text: string) => void;
  setSelectedCustomImage: (url: string | null) => void;
  selectedImage: SelectedImage | null;
  onFileListFetched: (fileList: BgFile[]) => void;
}


// Discriminated union types
interface FolderWithType extends Folder {
  type: 'folder';
}

interface BgFileWithType extends BgFile {
  type: 'file';
}

type CombinedListItem = FolderWithType | BgFileWithType;

const AssetsTab: React.FC<AssetsTabProps> = ({
  userEmail,
  setSelectedImage,
  setDescribeText,
  setDontWantText,
  setSelectedCustomImage,
  selectedImage,
  onFileListFetched,
}) => {
  const {
    setUserEmail,
    allFolders,
    setAllFolders,
    currentFolderId,
    setCurrentFolderId,
    filteredFolders,
    setFilteredFolders,
    filteredFiles,
    setFilteredFiles,
    fetchFoldersAndFiles,
    createFolder,
    deleteFile,
  } = useFolderContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>('');
  const [creatingFolder, setCreatingFolder] = useState<boolean>(false);
  const [isCreatingFolder, setIsCreatingFolder] = useState<boolean>(false); // 폴더 생성 UI 토글 상태

  // 이메일 주소를 안전한 디렉토리 이름으로 변환하는 함수
  const sanitizeUserEmail = (email: string): string => { 
    return email.replace(/[^a-zA-Z0-9]/g, '_');
  };

  // 현재 폴더의 경로를 추적하기 위한 상태
  const [currentPath, setCurrentPath] = useState<string>('Root');
  const [pathStack, setPathStack] = useState<string[]>(['root']); // 경로 추적을 위한 스택

  useEffect(() => {
    setUserEmail(userEmail); // FolderContext에 이메일 정보 전달
  }, [userEmail, setUserEmail]);

  useEffect(() => {
    // Reset selectedImage when AssetsTab mounts
    setSelectedImage(null);
    // Optionally, reset other related states
    setDescribeText('');
    setDontWantText('');
    setSelectedCustomImage(null);

    // Cleanup function (if needed in the future)
    return () => {
      // Perform any necessary cleanup here
    };

  }, [setSelectedImage, setDescribeText, setDontWantText, setSelectedCustomImage]);
  
  // 폴더 내비게이션 경로 업데이트 함수
  useEffect(() => {
    const updatePath = async () => {
      if (currentFolderId === 'root') {
        setCurrentPath('Root');
        return;
      }

      try {
        const response = await axiosInstance.get(
          `/get_all_folders`,
          { withCredentials: true }
        );

        if (response.status === 200) {
          const { folders } = response.data;
          let path = [];
          let currentId = currentFolderId;

          while (currentId && currentId !== 'root') {
            const folder = folders[currentId];
            if (!folder) break;
            path.unshift(folder.name);
            currentId = folder.parent_uuid;
          }

          setCurrentPath(['Root', ...path].join(' / '));
        }
      } catch (error) {
        console.error('현재 경로를 가져오는 중 오류 발생:', error);
      }
    };

    updatePath();
  }, [currentFolderId]);

  const stableFetchFoldersAndFiles = useCallback(fetchFoldersAndFiles, []);

  useEffect(() => {
    const initializeAssetsTab = async () => {
      setLoading(true);
      setCurrentFolderId('root'); // 루트로 초기화
      setPathStack(['root']); // 경로 초기화
      setCurrentPath('Root');
      setCookie('current_folder_id', 'root', 1); // current_folder_id 쿠키 설정
      setCookie('save_folder_id', 'root', 1); // save_folder_id 쿠키 설정
      await stableFetchFoldersAndFiles('root'); // 초기 데이터 로드
      setLoading(false);
    };
  
    initializeAssetsTab(); // 컴포넌트 초기화 시 한 번만 실행
  }, []); // 의존성을 비움
  
  useEffect(() => {
    const loadFolderData = async () => {
      setLoading(true);
      await stableFetchFoldersAndFiles(currentFolderId); // currentFolderId가 변경될 때만 실행
      setLoading(false);
    };
  
    loadFolderData(); // currentFolderId 변경 시 실행
  }, [currentFolderId]); // 의존성 최소화
  

  // AssetsTab 초기화 상태 추가
  useEffect(() => {
    const handleResetAssetsTab = async () => {
      setLoading(true);
      setCurrentFolderId('root'); // 루트 폴더로 초기화
      setPathStack(['root']); // 경로 초기화
      setCurrentPath('Root');
      setCookie('current_folder_id', 'root', 1); // current_folder_id 쿠키 설정
      await fetchFoldersAndFiles('root'); // 초기 데이터 로드
      setLoading(false);
    };

    window.addEventListener('resetAssetsTab', handleResetAssetsTab);
    return () => window.removeEventListener('resetAssetsTab', handleResetAssetsTab);
  }, [fetchFoldersAndFiles]);

  // 폴더 생성 핸들러
  const handleCreateFolder = async () => {
    if (!folderName.trim()) {
      toast.warning('Enter a folder name.');
      return;
    }
    setCreatingFolder(true);
    try {
      await createFolder(folderName.trim());
      setFolderName('');
      setIsCreatingFolder(false);
      // 현재 폴더의 하위 폴더와 파일을 다시 가져옵니다.
      await fetchFoldersAndFiles(currentFolderId);
    } catch (error) {
      toast.error('An error occurred while creating the folder.');
    } finally {
      setCreatingFolder(false);
    }
  };

  // 폴더 생성 UI 토글 핸들러
  const toggleFolderCreation = () => {
    setIsCreatingFolder((prev) => !prev);
    if (isCreatingFolder) {
      setFolderName('');
    }
  };

  // 폴더 클릭 시 폴더 내비게이션
  const handleFolderClick = async (folderId: string) => {
    setPathStack((prev) => [...prev, folderId]);
    setCurrentFolderId(folderId);
    setCookie('current_folder_id', folderId, 1); // current_folder_id 쿠키 설정
    setCookie('save_folder_id', folderId, 1);

    console.log('## current_folder_id', folderId)
  };

  // 상위 폴더로 돌아가기 핸들러
  const handleBackToParent = async () => {
    if (pathStack.length <= 1) return; // 이미 루트 폴더에 있음

    const newPathStack = [...pathStack];
    newPathStack.pop(); // 현재 폴더 제거
    const parentFolderId = newPathStack[newPathStack.length - 1];
    setPathStack(newPathStack);
    setCurrentFolderId(parentFolderId);
    setCookie('current_folder_id', parentFolderId, 1); // current_folder_id 쿠키 설정
    setCookie('save_folder_id', parentFolderId, 1);

    console.log('## current_folder_id', parentFolderId)
  };

  // 현재 폴더의 파일 클릭 시 처리 함수
  const handleImageClick = async (bgFile: BgFile) => {
    try {
      const newSelectedImage: SelectedImage = {
        id: bgFile.file_hash,
        url: bgFile.url,
        source: 'assets',
        originalUrl: bgFile.url, // originalUrl 추가
      };
      if (selectedImage && selectedImage.id === bgFile.file_hash) {
        setSelectedImage(null);
      } else {
        setSelectedImage(newSelectedImage);
        setCookie('save_folder_id', currentFolderId, 1); // save_folder_id 쿠키 설정
      }
      setCookie('file_hash', `PIXMON:${bgFile.file_hash}`, 1); // file_hash 쿠키 설정

      // 현재 폴더에 속한 파일만 필터링 (필요 시)
      const filesInFolder = filteredFiles.filter(
        (file: BgFile) => file.folder_id === currentFolderId
      );
      onFileListFetched(filesInFolder);

      setDescribeText('');
      setDontWantText('');
      setSelectedCustomImage(null);

      toast.success('이미지가 선택되었습니다.');


      // 선택된 이미지에 대한 추가 정보 요청 (필요 시)
      const response = await axiosInstance.post(
        '/bgfile_gi_list',
        { filename: bgFile.filename },
        { withCredentials: true }
      );

      if (response.data && response.data.files) {
        onFileListFetched(response.data.files);
      }
    } catch (error) {
      console.error('배경 이미지 선택 중 오류 발생:', error);
      toast.error('Failed to select an image.');
    }
  };

  const handleDelete = async (fileHash: string) => {
    await deleteFile(fileHash);
  };

  // 쿠키 관리 헬퍼 함수
  const getCookie = (name: string): string | null => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return decodeURIComponent(match[2]);
    return null;
  };

  const setCookie = (name: string, value: string, days: number = 1) => {
    const serviceDomain = process.env.REACT_APP_SERVICE_DOMAIN;

    if (!serviceDomain) {
      console.error('환경변수 REACT_APP_SERVICE_DOMAIN이 설정되지 않았습니다.');
      return;
    }
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};Domain=${serviceDomain}; path=/;Secure;SameSite=Strict`;
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  // 현재 폴더의 하위 폴더와 파일을 합침
  const combinedList: CombinedListItem[] = [
    ...filteredFolders.map((folder) => ({ ...folder, type: 'folder' as const })),
    ...filteredFiles.map((bgFile) => ({ ...bgFile, type: 'file' as const })),
  ];

  return (
    <Box>
      {/* 상단 타이틀 및 폴더 생성 버튼 */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h5" fontWeight="bold" sx={{ color: '#e6a900' }}>Assets</Typography>
        <IconButton
          color="primary"
          aria-label="Create folders"
          sx={{ fontSize: '3rem' }}
          onClick={toggleFolderCreation} // 폴더 생성 토글 핸들러 연결
        >
          <CreateNewFolderIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 2 }} /> {/* 경계선 추가 */}

      {/* 폴더 생성 입력창 및 버튼 */}
      {isCreatingFolder && (
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            label="Folder name"
            variant="outlined"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateFolder}
            disabled={creatingFolder}
          >
            {creatingFolder ? 'Creating...' : 'Create'}
          </Button>
        </Box>
      )}

      {/* 폴더 탐색 버튼 */}
      {pathStack.length > 1 && (
        <Box sx={{ mb: 2 }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={handleBackToParent}
          >
            Go back
          </Button>
        </Box>
      )}

      {/* 현재 폴더의 경로 표시 */}
      {/* <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Current Path: {currentPath}</Typography>
      </Box> */}

      {/* 폴더 및 이미지 파일 목록을 한 줄에 2개씩 출력 */}
      <Grid container spacing={2}>
        {combinedList.map((item: CombinedListItem) => {
          if (item.type === 'folder') {
            return (
              <Grid item xs={6} sm={6} md={6} key={item.id}>
                <Card
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    border: '2px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    '&:hover .overlay': {
                      opacity: 1,
                    },
                  }}
                  onClick={() => handleFolderClick(item.id)}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      width: '100%',
                      aspectRatio: '1 / 1',
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#f0f0f0',
                    }}
                  >
                    <FolderIcon sx={{ fontSize: 60, color: '#FFA726' }} />
                    <Typography
                      variant="body2"
                      sx={{
                        position: 'absolute',
                        bottom: '8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        color: 'white',
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        pointerEvents: 'none',
                      }}
                    >
                      {item.name}
                    </Typography>
                  </CardMedia>
                </Card>
              </Grid>
            );
          } else {
            // item.type === 'file'
            return (
              <Grid item xs={6} sm={6} md={6} key={item.file_hash}>
                <Card
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    border: '2px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    '&:hover .overlay': {
                      opacity: 1,
                    },
                  }}
                  onClick={() => handleImageClick(item)}
                >
                  <CardMedia
                    component="img"
                    image={item.url}
                    alt={item.filename}
                    sx={{
                      width: '100%',
                      aspectRatio: '1',
                      objectFit: 'cover',
                    }}
                  />
                  {/* 마우스 오버 시 표시될 오버레이 */}
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      padding: 1,
                      opacity: 0,
                      transition: 'opacity 0.3s',
                    }}
                  >
                    <Checkbox
                      sx={{
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                      checked={selectedImage?.id === item.file_hash}
                      onChange={() => handleImageClick(item)}
                    />
                    <IconButton
                      sx={{ color: 'white' }}
                      onClick={(e) => {
                        e.stopPropagation(); // 이벤트 전파 방지
                        handleDelete(item.file_hash);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  {selectedImage && selectedImage.id === item.file_hash && (
                    <SelectedOverlay />
                  )}
                </Card>
              </Grid>
            );
          }
        })}
      </Grid>

      {/* 폴더 및 이미지가 모두 없는 경우 */}
      {filteredFolders.length === 0 && filteredFiles.length === 0 && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h6">There are no images.</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // 첫 이미지 생성 로직 추가
              toast.info('The ability to create your first image is not yet implemented.');
            }}
          >
            Create your first image
          </Button>
        </Box>
      )}

      {/* 현재 폴더의 경로 표시 */}
      {/* <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="textSecondary">
        Current Folder: {currentPath}
        </Typography> */}
      <p></p>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">
          Current Path: {currentPath === 'Root' ? '/' : currentPath.replace('Root /', '/')}
        </Typography>
      </Box>
      {/* </Box> */}
    </Box>
  );
};

export default AssetsTab;
