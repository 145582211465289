// src/pages/Cancel.tsx
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Typography, Container } from "@mui/material";
import { Link } from 'react-router-dom';

const Cancel: React.FC = () => {
    React.useEffect(() => {
        toast.error("The payment was canceled.");
    }, []);

    return (
        <Container style={{ textAlign: 'center', padding: '50px' }}>
            <Typography variant="h4" gutterBottom>
            Cancel a payment
            </Typography>
            <Typography variant="body1">
            Your payment was canceled. Please try again.
            </Typography>
            <Button variant="contained" color="primary" component={Link} to="/" style={{ marginTop: '20px' }}>
            Return to Home
            </Button>
        </Container>
    );
};

export default Cancel;
