// src/pages/Subscribe.tsx

import React, { useState } from "react";
import { Button, Card, CardContent, Typography, Grid } from "@mui/material";
import axios from "axios";
import axiosInstance from '../api/axios';
import { useAuth } from "../contexts/AuthContext";
import { toast } from "react-toastify";
import * as PortOne from "@portone/browser-sdk/v2";
import { v4 as uuidv4 } from 'uuid'; // UUID 생성 라이브러리

const { REACT_APP_STORE_ID, REACT_APP_CHANNEL_KEY, REACT_APP_BASE_URL } = process.env;

function generateUniqueString() {
    // UUID를 생성
    const uuid = uuidv4();

    // UUID에서 하이픈("-") 제거
    const uniqueString = uuid.replace(/-/g, '');

    return uniqueString;
}

const Subscribe: React.FC = () => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubscribe = async (subscriptionType: string) => {
        if (!currentUser) {
            toast.error("Login required.");
            return;
        }
        
        setLoading(true);

        try {
            const token = await currentUser.getIdToken();

            // 백엔드의 /api/item 엔드포인트에서 결제 항목 정보 가져오기
            const itemResponse = await axiosInstance.get("/item", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            const items = itemResponse.data;
            let item: any;

            if (subscriptionType === "Free") {
                // Free 구독은 결제가 필요 없으므로 백엔드에서 직접 처리
                const freeResponse = await axiosInstance.post("/subscription/free", {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                if (freeResponse.status === 200) {
                    toast.success("Free subscription has been activated!");
                } else {
                    toast.error("An error occurred while activating Free subscription.");
                }
                return;
            } else if (subscriptionType === "Basic") {
                item = items.basic_subscription;
            } else if (subscriptionType === "Premium") {
                item = items.premium_subscription;
            } else {
                toast.error("Invalid subscription type.");
                return;
            }

            if (!item) {
                toast.error("Invalid payment item.");
                return;
            }

            // 결제 ID 생성
            // const paymentId = `payment-${uuidv4()}`;
            // const paymentId = `12345677890`

            console.log('STORE_ID:', REACT_APP_STORE_ID);
            console.log('CHANNEL_KEY:', REACT_APP_CHANNEL_KEY);
            console.log('BASE_URL:', REACT_APP_BASE_URL);
            console.log('orderName: ', item.name)
            console.log('totalAmount: ', item.price)
            console.log('currency: ', item.currency)
            console.log('user_id: ', currentUser.uid)
            console.log('subscription_type: ', subscriptionType)


            // PortOne SDK를 사용하여 결제 요청
            const payment = await PortOne.requestPayment({
                storeId: REACT_APP_STORE_ID,
                channelKey: REACT_APP_CHANNEL_KEY,
                // paymentId: `payment${crypto.randomUUID()}`,
                paymentId: `payment${generateUniqueString()}`,
                // paymentId: `1234567890`,
                orderName: item.name,
                totalAmount: item.price,
                currency: item.currency,
                payMethod: "CARD",
                // customData: { // 객체로 전달
                //     user_id: currentUser.uid,
                //     subscription_type: subscriptionType,
                // },
                customer: {
                    phoneNumber: "010-0000-1234",
                  },
                redirectUrl: `${REACT_APP_BASE_URL}/payment-redirect`, // 결제 완료 후 리다이렉트할 URL
            });

            if (!payment) {
                toast.error("Payment request failed.");
                return;
            }

            if (payment.code !== undefined) {
                // 결제 과정에서 오류 발생
                toast.error(payment.message || "An error occurred while processing payment.");
                return;
            }

            // 결제가 성공적으로 진행 중이라면, 백엔드에서 결제 완료를 처리할 엔드포인트를 호출합니다.
            const completeResponse = await axiosInstance.post(`/payment/complete`, {
                paymentId: payment.paymentId,
                subscriptionType: subscriptionType,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (completeResponse.status === 200) {
                toast.success("Payment completed successfully.");
            } else {
                toast.error("An error occurred while processing payment completion.");
            }

        } catch (error: any) {
            console.error("Subscription error:", error);
            toast.error(error.response?.data?.detail || "An error occurred while processing subscription.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: '50px', textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
                Select Subscription
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Free</Typography>
                            <Typography variant="body1">Free Subscription</Typography>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                fullWidth 
                                onClick={() => handleSubscribe("Free")}
                                disabled={loading}
                                style={{ marginTop: '20px' }}
                            >
                                Select
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Basic</Typography>
                            <Typography variant="body1">100 KRW / month</Typography>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                fullWidth 
                                onClick={() => handleSubscribe("Basic")}
                                disabled={loading}
                                style={{ marginTop: '20px' }}
                            >
                                Select
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Premium</Typography>
                            <Typography variant="body1">200 KRW / month</Typography>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                fullWidth 
                                onClick={() => handleSubscribe("Premium")}
                                disabled={loading}
                                style={{ marginTop: '20px' }}
                            >
                                Select
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default Subscribe;
