// src/pages/ProtectedPage.tsx
import React, { useEffect, useState } from "react";
import axiosInstance from "../api/axios";

const ProtectedPage: React.FC = () => {
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get("/me");
                setData(response.data);
            } catch (err: any) {
                setError(err.response?.data?.detail || "Failed to fetch data");
            }
        };

        fetchData();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <div className="protected-container">
            <h2>Protected Page</h2>
            <p>Hello, {data.display_name || "PixMon User"}!</p>
            <p>Email: {data.email}</p>
            {/* Additional content */}
        </div>
    );
};

export default ProtectedPage;
