// src/pages/Gallery.tsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  TextField,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share'; // Import Share Icon
import axiosInstance from '../api/axios';
import { toast } from 'react-toastify';
import Navbar from '../components/Navbar';
import { useAuth } from '../contexts/AuthContext';
import { AxiosResponse } from 'axios';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import RedditIcon from '@mui/icons-material/Reddit';
import InfiniteScroll from 'react-infinite-scroll-component'; // Import InfiniteScroll

// Define the VoteResponse interface
interface VoteResponse {
  likes: number;
  dislikes: number;
  user_vote: number; // 1: 좋아요, -1: 싫어요, 0: 투표 없음
}

// Define the Comment interface
interface Comment {
  id: number;
  nickname: string; // user_id 대신 nickname 사용
  image_id: string;
  parent_id: number | null;
  content: string;
  created_at: string;
  replies: Comment[];
  likes: number;
  dislikes: number;
  user_vote: number; // 1: 좋아요, -1: 싫어요, 0: 투표 없음
}

// Define the ImageItem interface
interface ImageItem {
  id: string;
  user_id: string;
  nickname: string;
  image_url: string;
  description?: string;
  tags?: string;
  created_at: string;
  comments: Comment[];
  likes: number;
  dislikes: number;
  user_vote: number; // 1: 좋아요, -1: 싫어요, 0: 투표 없음
}

const Gallery: React.FC = () => {
  const [images, setImages] = useState<ImageItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  // Modal related state
  const [selectedImage, setSelectedImage] = useState<ImageItem | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  // Share Modal state
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

  // Comment input state
  const [newComment, setNewComment] = useState<string>('');
  const [replyingToCommentId, setReplyingToCommentId] = useState<number | null>(null);

  // 'Read More' state for image descriptions
  const [expandedDescriptions, setExpandedDescriptions] = useState<Set<string>>(new Set());

  // Expanded comments state for collapsible replies
  const [expandedComments, setExpandedComments] = useState<Set<number>>(new Set());

  // Get current user from AuthContext
  const { currentUser } = useAuth();

  // Fetch images function
  const fetchImages = useCallback(async (pageNum: number) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/gallery?page=${pageNum}&limit=8`); // Changed limit to 8
      console.log("Gallery API Response:", res.data); // 응답 데이터 확인

      let newImages: ImageItem[] = [];

      // Backend returns an array directly
      if (Array.isArray(res.data)) {
        newImages = res.data;
      } else {
        throw new Error("Unexpected API response structure");
      }

      // Initialize fields if missing
      newImages = newImages.map(img => ({
        ...img,
        comments: img.comments || [],
        likes: img.likes || 0,
        dislikes: img.dislikes || 0,
        user_vote: img.user_vote || 0,
      }));

      if (newImages.length === 0) {
        setHasMore(false);
      } else {
        setImages((prev) => [...prev, ...newImages]);
      }
    } catch (error) {
      console.error('Failed to load gallery:', error);
      toast.error('Failed to load gallery.');
      setHasMore(false); // Stop further fetching on error
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch images when page changes
  useEffect(() => {
    fetchImages(page);
  }, [page, fetchImages]);

  // Load more handler (triggered by InfiniteScroll)
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setPage((prev) => prev + 1);
    }
  };

  // Open modal
  const handleOpenModal = (image: ImageItem) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
    setNewComment('');
    setReplyingToCommentId(null);
    setExpandedComments(new Set());
  };

  // Open Share Modal
  const handleOpenShareModal = () => {
    setShareModalOpen(true);
  };

  // Close Share Modal
  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };

  // Add comment
  const handleAddComment = async () => {
    if (!newComment.trim() || !selectedImage) return;

    try {
      const payload = { content: newComment, parent_id: replyingToCommentId };
      const res = await axiosInstance.post(`/gallery/${selectedImage.id}/comments`, payload);
      console.log("Created comment:", res.data);

      // Add comment to state
      if (selectedImage) {
        let updatedComments: Comment[] = [];

        if (payload.parent_id === null) {
          // Main comment
          updatedComments = [res.data, ...selectedImage.comments];
        } else {
          // Reply
          const addReply = (comments: Comment[]): Comment[] => {
            return comments.map(comment => {
              if (comment.id === payload.parent_id) {
                return { ...comment, replies: [res.data, ...comment.replies] };
              } else if (comment.replies.length > 0) {
                return { ...comment, replies: addReply(comment.replies) };
              }
              return comment;
            });
          };
          updatedComments = addReply(selectedImage.comments);
        }

        setSelectedImage({
          ...selectedImage,
          comments: updatedComments,
        });

        // Update gallery list
        setImages((prev) =>
          prev.map((img) =>
            img.id === selectedImage.id
              ? { ...img, comments: updatedComments }
              : img
          )
        );
      }

      // Reset input
      setNewComment('');
      setReplyingToCommentId(null);

      toast.success('Comment added successfully!');
    } catch (error) {
      console.error('Failed to add comment:', error);
      toast.error('Failed to add comment.');
    }
  };

  // Image vote handler
  const handleImageVote = async (imageId: string, voteType: number) => {
    try {
      let res: AxiosResponse<VoteResponse>;

      if (voteType === 1) {
        // 좋아요 요청
        res = await axiosInstance.post(`/gallery/${imageId}/like`);
      } else if (voteType === -1) {
        // 싫어요 요청
        res = await axiosInstance.post(`/gallery/${imageId}/dislike`);
      } else {
        // 유효하지 않은 voteType
        throw new Error('Invalid vote type');
      }

      console.log(`${voteType === 1 ? "Liked" : "Disliked"} image:`, res.data);

      // Update image state
      setImages((prev) =>
        prev.map((img) =>
          img.id === imageId
            ? { ...img, likes: res.data.likes, dislikes: res.data.dislikes, user_vote: res.data.user_vote }
            : img
        )
      );

      if (selectedImage && selectedImage.id === imageId) {
        setSelectedImage({
          ...selectedImage,
          likes: res.data.likes,
          dislikes: res.data.dislikes,
          user_vote: res.data.user_vote,
        });
      }

      toast.success(`${voteType === 1 ? 'Liked' : 'Disliked'} successfully!`);
    } catch (error) {
      console.error(`Failed to ${voteType === 1 ? "like" : "dislike"} image:`, error);
      toast.error(`Failed to ${voteType === 1 ? 'like' : 'dislike'}.`);
    }
  };

  // Comment vote handler
  const handleCommentVote = async (commentId: number, voteType: number) => {
    try {
      let res: AxiosResponse<VoteResponse>;

      if (voteType === 1) {
        // 댓글 좋아요 요청
        res = await axiosInstance.post(`/gallery/${commentId}/like`);
      } else if (voteType === -1) {
        // 댓글 싫어요 요청
        res = await axiosInstance.post(`/gallery/${commentId}/dislike`);
      } else {
        // 유효하지 않은 voteType
        throw new Error('Invalid vote type');
      }

      console.log(`${voteType === 1 ? "Liked" : "Disliked"} comment:`, res.data);

      // Update comment state
      const updateComments = (comments: Comment[]): Comment[] => {
        return comments.map(comment => {
          if (comment.id === commentId) {
            return { ...comment, likes: res.data.likes, dislikes: res.data.dislikes, user_vote: res.data.user_vote };
          } else if (comment.replies.length > 0) {
            return { ...comment, replies: updateComments(comment.replies) };
          }
          return comment;
        });
      };

      setImages(prev =>
        prev.map(img => {
          if (img.id === selectedImage?.id) {
            return { ...img, comments: updateComments(img.comments) };
          }
          return img;
        })
      );

      if (selectedImage) {
        setSelectedImage({
          ...selectedImage,
          comments: updateComments(selectedImage.comments),
        });
      }

      toast.success(`${voteType === 1 ? 'Liked' : 'Disliked'} successfully!`);
    } catch (error) {
      console.error(`Failed to ${voteType === 1 ? "like" : "dislike"} comment:`, error);
      toast.error(`Failed to ${voteType === 1 ? 'like' : 'dislike'}.`);
    }
  };

  // Like Image Click Handler
  const handleLikeImageClick = (imageId: string) => {
    handleImageVote(imageId, 1);
  };

  // Dislike Image Click Handler
  const handleDislikeImageClick = (imageId: string) => {
    handleImageVote(imageId, -1);
  };

  // Like Comment Click Handler
  const handleLikeCommentClick = (commentId: number) => {
    handleCommentVote(commentId, 1);
  };

  // Dislike Comment Click Handler
  const handleDislikeCommentClick = (commentId: number) => {
    handleCommentVote(commentId, -1);
  };

  // Toggle replies visibility
  const toggleCommentReplies = (commentId: number) => {
    setExpandedComments(prev => {
      const newSet = new Set(prev);
      if (newSet.has(commentId)) {
        newSet.delete(commentId);
      } else {
        newSet.add(commentId);
      }
      return newSet;
    });
  };

  // Delete Image Handler
  const handleDeleteImage = async (imageId: string) => {
    // Confirm deletion
    if (!window.confirm('Are you sure you want to delete this image? This action cannot be undone.')) {
      return;
    }

    try {
      const res = await axiosInstance.delete(`/gallery/${imageId}`);
      console.log("Deleted image:", res.data);

      // Remove image from state
      setImages((prevImages) => prevImages.filter((image) => image.id !== imageId));

      // Close modal
      handleCloseModal();

      toast.success('Image and its comments have been successfully deleted!');
    } catch (error) {
      console.error('Failed to delete image:', error);
      toast.error('Failed to delete image.');
    }
  };

  // Helper function to truncate description
  const truncateDescription = (description: string | undefined): string => {
    if (!description) return '';
    const lines = description.split('\n');
    if (lines.length <= 2) {
      return description;
    }
    return lines.slice(0, 2).join('\n') + '...';
  };

  // Function to copy text to clipboard
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('URL copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast.error('Failed to copy URL.');
    }
  };

  // Function to generate share URLs
  const generateShareUrls = (url: string) => {
    const encodedUrl = encodeURIComponent(url);
    const encodedText = encodeURIComponent('Check out this image!');

    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`,
      reddit: `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedText}`,
      // instagram: '', // Instagram doesn't support direct sharing via URL
      email: `mailto:?subject=${encodedText}&body=${encodedUrl}`,
    };
  };

  // Render comments and replies
  const renderComments = (comments: Comment[], level: number = 0) => {
    return comments.map((comment) => (
      <Box key={comment.id} sx={{ mb: 2, pl: level * 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            {comment.nickname}
          </Typography>
          <Typography variant="body2">{comment.content}</Typography>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation(); // Prevent click event propagation
              setReplyingToCommentId(prevId => (prevId === comment.id ? null : comment.id));
            }}
            sx={{ ml: 'auto' }}
          >
            <ChatBubbleOutlineIcon fontSize="small" />
          </IconButton>
        </Box>

        {/* Reply Count and Expand/Collapse Button */}
        {comment.replies.length > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', ml: level * 4, mt: 0.5 }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {comment.replies.length} {comment.replies.length > 1 ? 'Replies' : 'Reply'}
            </Typography>
            <Button
              size="small"
              onClick={() => toggleCommentReplies(comment.id)}
            >
              {expandedComments.has(comment.id) ? 'Hide Replies' : 'Show Replies'}
            </Button>
          </Box>
        )}

        {/* Replies List */}
        {comment.replies.length > 0 && expandedComments.has(comment.id) && (
          <Box sx={{ mt: 1 }}>
            {renderComments(comment.replies, level + 1)}
          </Box>
        )}

        {/* Comment Like/Dislike Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <IconButton
            onClick={() => handleLikeCommentClick(comment.id)}
            color={comment.user_vote === 1 ? 'primary' : 'default'}
          >
            <ThumbUpIcon />
          </IconButton>
          <Typography variant="body2" sx={{ mr: 2 }}>
            {comment.likes}
          </Typography>
          <IconButton
            onClick={() => handleDislikeCommentClick(comment.id)}
            color={comment.user_vote === -1 ? 'error' : 'default'}
          >
            <ThumbDownIcon />
          </IconButton>
          <Typography variant="body2">
            {comment.dislikes}
          </Typography>
        </Box>

        {/* Reply Input Field */}
        {replyingToCommentId === comment.id && (
          <Box sx={{ mt: 1, pl: 4 }}>
            <TextField
              label="Add a reply"
              variant="outlined"
              fullWidth
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddComment();
                }
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              sx={{ mt: 1 }}
            >
              Submit
            </Button>
          </Box>
        )}
      </Box>
    ));
  };

  return (
    <>
      {/* Navigation Bar */}
      <Navbar />

      {/* Main Content Area */}
      <Box sx={{ padding: '30px 10px 40px 10px' }}> {/* Adjust padding based on Navbar height */}
        <Typography variant="h4" gutterBottom>
          PixMon Gallery
        </Typography>
        
        {/* Infinite Scroll Component */}
        <InfiniteScroll
          dataLength={images.length} // This is important field to render the next data
          next={handleLoadMore}
          hasMore={hasMore}
          loader={
            <Box textAlign="center" mt={2}>
              <CircularProgress />
            </Box>
          }
          endMessage={
            <Typography variant="body2" color="textSecondary" align="center" mt={2}>
              No more images to load.
            </Typography>
          }
          style={{ overflow: 'visible' }} // To ensure MUI Grid behaves correctly
        >
          <Grid container spacing={2}>
            {images.map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <Card
                  sx={{ minHeight: 400, cursor: 'pointer' }}
                  onClick={() => handleOpenModal(item)}
                >
                  <CardContent>
                    {/* Image */}
                    <img
                      src={item.image_url}
                      alt={`Gallery ${item.id}`}
                      style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                    />

                    {/* Owner Info and Comment Count */}
                    <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Owner: {item.nickname}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                        <ChatBubbleOutlineIcon fontSize="small" sx={{ mr: 0.5 }} />
                        {item.comments.length}
                      </Typography>
                    </Stack>

                    {/* Description */}
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 1,
                        whiteSpace: 'pre-line',
                        display: '-webkit-box',
                        WebkitLineClamp: expandedDescriptions.has(item.id) ? undefined : 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      {truncateDescription(item.description)}
                    </Typography>
                    {item.description && item.description.split('\n').length > 2 && (
                      <Button size="small" onClick={(e) => {
                        e.stopPropagation(); // Prevent card click
                        setExpandedDescriptions(prev => {
                          const newSet = new Set(prev);
                          if (newSet.has(item.id)) {
                            newSet.delete(item.id);
                          } else {
                            newSet.add(item.id);
                          }
                          return newSet;
                        });
                      }}>
                        {expandedDescriptions.has(item.id) ? 'Show Less' : 'Read More'}
                      </Button>
                    )}

                    {/* Image Like/Dislike Section */}
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click
                          handleLikeImageClick(item.id);
                        }}
                        color={item.user_vote === 1 ? 'primary' : 'default'}
                      >
                        <ThumbUpIcon />
                      </IconButton>
                      <Typography variant="body2" sx={{ mr: 2 }}>
                        {item.likes}
                      </Typography>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click
                          handleDislikeImageClick(item.id);
                        }}
                        color={item.user_vote === -1 ? 'error' : 'default'}
                      >
                        <ThumbDownIcon />
                      </IconButton>
                      <Typography variant="body2">
                        {item.dislikes}
                      </Typography>

                      {/* Share Button */}
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click
                          setSelectedImage(item);
                          handleOpenShareModal();
                        }}
                        sx={{ ml: 'auto' }}
                        aria-label="share"
                      >
                        <ShareIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </Box>

      {/* Detailed Information Modal */}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        {selectedImage && (
          <>
            <DialogTitle>
              Details
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <img
                    src={selectedImage.image_url}
                    alt={`Gallery ${selectedImage.id}`}
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Owner: {selectedImage.nickname}</Typography>
                  <Typography variant="body1" sx={{ mt: 2, whiteSpace: 'pre-line' }}>
                    {selectedImage.description}
                  </Typography>
                  {/* Tags Information */}
                  {selectedImage.tags && (
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                      Tags: {selectedImage.tags}
                    </Typography>
                  )}
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <IconButton
                      onClick={() => handleLikeImageClick(selectedImage.id)}
                      color={selectedImage.user_vote === 1 ? 'primary' : 'default'}
                    >
                      <ThumbUpIcon />
                    </IconButton>
                    <Typography variant="body2" sx={{ mr: 2 }}>
                      {selectedImage.likes}
                    </Typography>
                    <IconButton
                      onClick={() => handleDislikeImageClick(selectedImage.id)}
                      color={selectedImage.user_vote === -1 ? 'error' : 'default'}
                    >
                      <ThumbDownIcon />
                    </IconButton>
                    <Typography variant="body2">
                      {selectedImage.dislikes}
                    </Typography>

                    {/* Share Button */}
                    <IconButton
                      onClick={handleOpenShareModal}
                      sx={{ ml: 'auto' }}
                      aria-label="share"
                    >
                      <ShareIcon />
                    </IconButton>
                  </Box>

                  {/* Conditional Delete Button */}
                  {currentUser && selectedImage.user_id === currentUser.uid && (
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteImage(selectedImage.id)}
                      >
                        Delete Image
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              {/* Comments Section */}
              <Typography variant="h6" gutterBottom>
                Comments
              </Typography>

              {/* Comment Input */}
              <Box sx={{ display: 'flex', mb: 2 }}>
                <TextField
                  label="Add a comment"
                  variant="outlined"
                  fullWidth
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleAddComment();
                    }
                  }}
                  disabled={replyingToCommentId !== null}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddComment}
                  sx={{ ml: 2 }}
                  disabled={replyingToCommentId !== null}
                >
                  Submit
                </Button>
              </Box>

              {/* Comments List */}
              {selectedImage.comments && selectedImage.comments.length > 0 ? (
                renderComments(selectedImage.comments.slice(0, 10))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No comments yet.
                </Typography>
              )}

              {/* Load More Comments Button */}
              {selectedImage.comments.length > 10 && (
                <Box textAlign="center" mt={2}>
                  <Button variant="outlined" onClick={() => {
                    // Implement Load More Comments Logic Here
                    toast.info('Load more comments functionality to be implemented.');
                  }}>
                    Load More Comments
                  </Button>
                </Box>
              )}
            </DialogContent>
          </>
        )}
      </Dialog>

      {/* Share Modal */}
      <Dialog
        open={shareModalOpen}
        onClose={handleCloseShareModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Share Image
          <IconButton
            aria-label="close"
            onClick={handleCloseShareModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedImage && (
            <>
              {/* Image Preview */}
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <img
                  src={selectedImage.image_url}
                  alt={`Gallery ${selectedImage.id}`}
                  style={{ width: '80%', height: 'auto', borderRadius: '8px' }}
                />
              </Box>

              {/* URL Copy Section */}
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                  label="Image URL"
                  variant="outlined"
                  fullWidth
                  value={selectedImage.image_url}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => copyToClipboard(selectedImage.image_url)}
                  sx={{ ml: 2 }}
                >
                  Copy
                </Button>
              </Box>

              {/* Social Share Buttons */}
              <Typography variant="subtitle1" gutterBottom>
                Share via:
              </Typography>
              <Stack direction="row" spacing={2}>
                {/* Facebook */}
                <IconButton
                  color="primary"
                  component="a"
                  href={generateShareUrls(selectedImage.image_url).facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share on Facebook"
                >
                  <FacebookIcon fontSize="small" />
                </IconButton>

                {/* Twitter */}
                <IconButton
                  color="primary"
                  component="a"
                  href={generateShareUrls(selectedImage.image_url).twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share on Twitter"
                >
                  <TwitterIcon fontSize="small" />
                </IconButton>

                {/* Reddit */}
                <IconButton
                  color="primary"
                  component="a"
                  href={generateShareUrls(selectedImage.image_url).reddit}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share on Reddit"
                >
                  <RedditIcon fontSize="small" />
                </IconButton>

                {/* Email */}
                <IconButton
                  color="primary"
                  component="a"
                  href={generateShareUrls(selectedImage.image_url).email}
                  aria-label="Share via Email"
                >
                  <EmailIcon fontSize="small" />
                </IconButton>
              </Stack>
            </>
          )}
        </DialogContent>
      </Dialog>

    </>
  );
};

export default Gallery;
