// src/pages/Profile.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { toast } from "react-toastify";
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Avatar, Typography, Container } from "@mui/material";
import axiosInstance from '../api/axios';
import Subscribe from './Subscribe';
import Payments from './Payments';

interface UserProfile {
    uid: string;
    email: string;
    display_name: string;
    photo_url: string;
    email_verified: boolean;
    nickname: string | null;
    user_level: number;
    subscription_active: boolean;
    subscription_expires_at: string | null;
}

const Profile: React.FC = () => {
    const { currentUser } = useAuth();
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [nickname, setNickname] = useState<string>("");
    const [userLevel, setUserLevel] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");


    // const response = await axiosInstance.get(
    //     `https://121.78.116.47:8000/get_all_folders`,
    //     { withCredentials: true }
    //   );

    useEffect(() => {
        const fetchProfile = async () => {
            if (currentUser) {
                try {
                    const token = await currentUser.getIdToken();
                    // const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/me`, {
                    const response = await axiosInstance.get(
                        `/me`,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        }
                    );
                    setProfile(response.data);
                    setNickname(response.data.nickname || "");
                    setUserLevel(response.data.user_level || 1);
                } catch (err: any) {
                    setError(err.response?.data?.detail || "Failed to fetch profile.");
                    toast.error(`Failed to fetch profile: ${err.response?.data?.detail}`);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchProfile();
    }, [currentUser]);

    const handleUpdateProfile = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const token = await currentUser?.getIdToken();
            const formData = new FormData();
            formData.append("nickname", nickname);
            formData.append("user_level", userLevel.toString());

            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/me`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            setProfile(response.data);
            toast.success("Profile has been updated.");
        } catch (err: any) {
            setError(err.response?.data?.detail || "Failed to update profile.");
            toast.error(`Failed to update profile: ${err.response?.data?.detail}`);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!profile) {
        return <div>No profile data available.</div>;
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                My Profile
            </Typography>
            <Avatar src={profile.photo_url} alt="Profile" sx={{ width: 100, height: 100, margin: '0 auto' }} />
            <Typography variant="body1">Email: {profile.email}</Typography>
            <Typography variant="body1">Display Name: {profile.display_name}</Typography>
            <Typography variant="body1">Email Verified: {profile.email_verified ? "Yes" : "No"}</Typography>
            <Typography variant="body1">Subscription Status: {profile.subscription_active ? `Active${profile.subscription_expires_at ? ` (Expires: ${new Date(profile.subscription_expires_at).toLocaleString()})` : ""}` : "Inactive"}</Typography>
            <form onSubmit={handleUpdateProfile} style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '20px' }}>
                <TextField
                    label="Nickname"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                />
                <FormControl>
                    <InputLabel id="user-level-label">User Level</InputLabel>
                    <Select
                        labelId="user-level-label"
                        value={userLevel}
                        label="User Level"
                        onChange={(e) => setUserLevel(Number(e.target.value))}
                    >
                        <MenuItem value={1}>Basic</MenuItem>
                        <MenuItem value={2}>Standard</MenuItem>
                        <MenuItem value={3}>Premium</MenuItem>
                        {/* Add more levels as needed */}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" type="submit">
                    Update Profile
                </Button>
            </form>
            {!profile.subscription_active && (
                <div style={{ marginTop: '30px' }}>
                    <Typography variant="h5" gutterBottom>
                        Premium Subscription
                    </Typography>
                    <Subscribe />
                </div>
            )}
            {profile.subscription_active && (
                <div style={{ marginTop: '30px' }}>
                    <Typography variant="h5" gutterBottom>
                        Payment History
                    </Typography>
                    <Payments />
                </div>
            )}
        </Container>
    );
};

export default Profile;
