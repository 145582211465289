// src/pages/Payments.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { toast } from "react-toastify";
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Typography } from "@mui/material";

interface Payment {
    id: string;
    amount: number;
    currency: string;
    payment_status: string;
    subscription_id: string | null;
    subscription_status: string | null;
    subscription_expires_at: string | null;
    created_at: string;
}

const Payments: React.FC = () => {
    const { currentUser } = useAuth();
    const [payments, setPayments] = useState<Payment[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        const fetchPayments = async () => {
            if (currentUser) {
                try {
                    const token = await currentUser.getIdToken();
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/payments`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setPayments(response.data.payments);
                } catch (err: any) {
                    setError(err.response?.data?.detail || "Failed to fetch payment history.");
                    toast.error(`Failed to fetch payment history: ${err.response?.data?.detail}`);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchPayments();
    }, [currentUser]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Payment History
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Payment ID</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Currency</TableCell>
                        <TableCell>Payment Status</TableCell>
                        <TableCell>Subscription ID</TableCell>
                        <TableCell>Subscription Status</TableCell>
                        <TableCell>Subscription Expiry</TableCell>
                        <TableCell>Payment Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payments.map((payment) => (
                        <TableRow key={payment.id}>
                            <TableCell>{payment.id}</TableCell>
                            <TableCell>{payment.amount} {payment.currency}</TableCell>
                            <TableCell>{payment.currency}</TableCell>
                            <TableCell>{payment.payment_status}</TableCell>
                            <TableCell>{payment.subscription_id || "-"}</TableCell>
                            <TableCell>{payment.subscription_status || "-"}</TableCell>
                            <TableCell>{payment.subscription_expires_at ? new Date(payment.subscription_expires_at).toLocaleString() : "-"}</TableCell>
                            <TableCell>{new Date(payment.created_at).toLocaleString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
};

export default Payments;
